import logo from './assets/lrc-logo.svg';

function App() {
  return (
    <div class="splash">
      <img src={logo} alt="Little Red Couch Games: Coming soon." />
    </div>
  );
}

export default App;
